@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    min-height: 100%;
}

body {
    min-height: 100%;
}

.ql-toolbar.ql-snow {
    border: none; /* Remove toolbar border */
    background: none; /* Remove toolbar background */
    padding: 0;
  }
  
  .ql-container {
    border: none; /* Remove editor border */
  }
  
  .htmlContent ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  .htmlContent ol {
    list-style-type: decimal;
    padding-left: 20px;
  }

  .htmlContent ul li {
    margin-bottom: 5px;
  }

  .htmlContent ol li {
    margin-bottom: 10px;
  }

  .htmlContent p {
  }

  .htmlContent h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  .htmlContent a {
    color: #2563EB;
    text-decoration: underline;
  }
  