/* Popup.css */
.popup-container {
    position: relative;
    display: inline-block;
  }
  
  .popup-content {
    position: absolute; /* or absolute, depending on your needs */
    z-index: 100;
    /* Adjust positioning as needed */
    left: 50%;
    transform: translateX(0%);
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    white-space: nowrap;
  }
  
  
  .popup-container:hover .popup-content {
    display: block;
  }
  
  .text-sm { font-size: 0.8rem; }
  .text-md { font-size: 1rem; }
  .text-lg { font-size: 1.2rem; }
  